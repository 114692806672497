.navbar-brand{
    width: 28%;
    min-width: 16rem;
    max-width: 21rem;
}
.logoHeader{
    width: 100%;
}
.doctorBannerMain{
    opacity: 0;
    transform: translateY(8rem);
    animation: doctorPop 1s ease forwards;
}
@keyframes doctorPop {
    to{
        opacity: 1;
        transform: translateY(0);
    }
}