.galleryCard{
    border-radius: 1rem;
    transition: 0.5s all ease-in;
}
.galleryCard img{
    border-radius: inherit;
    transition: 0.1s all ease-in;
}
.galleryCard:hover .galleryCardImg{
    transform: rotate(2deg);
}